import React from 'react';
import Axios from 'axios';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Routes } from '../routes';

export const ApiContext = React.createContext();

export const ApiProvider = ({ children }) => {
  const history = useHistory();
  const firstMatch = useRouteMatch(`${Routes.ForgotPassword.path}`);
  const secondMatch = useRouteMatch(`${Routes.ResetPassword.path}`);
  const thirdMatch = useRouteMatch(`${Routes.Authorize.path}`);
  const fourthMatch = useRouteMatch(`${Routes.Signin.path}`);
  const fiveMatch = useRouteMatch(`${Routes.Signup.path}`);
  const sixMatch = useRouteMatch(`${Routes.StepWizard.path}`);

  const hasRedirect = !!(
    firstMatch ||
    secondMatch ||
    thirdMatch ||
    fourthMatch ||
    fiveMatch ||
    sixMatch
  );

  const axios = React.useMemo(() => {
    const instance = Axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem('_pc-t');
      const sid = localStorage.getItem('_pc-s');

      const newConfig = config;

      if (token) {
        newConfig.headers.Authorization = `Bearer ${decodeURIComponent(token)}`;
      }

      if (sid) {
        newConfig.headers['X-Printcart-Store-Sid'] = decodeURIComponent(sid);
      }

      return newConfig;
    });
    instance.interceptors.response.use(null, (err) => {
      if (err.request.status === 401 && !hasRedirect) {
        const currentUrl = window.location.href;
        const urlObj = new URL(currentUrl);

        if (urlObj.pathname !== Routes.Signin.path) {
          localStorage.setItem('pc_back_url', encodeURIComponent(currentUrl));

          return history.push(Routes.Signin.path);
        }
      }

      if (err.request.status === 400) {
        const newErr = {
          ...err,
          message: err.response.data.message,
        };

        return Promise.reject(newErr);
      }

      return Promise.reject(err);
    });

    return instance;
  }, [firstMatch, secondMatch, thirdMatch, fourthMatch, fiveMatch, sixMatch]);

  return <ApiContext.Provider value={axios}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return React.useContext(ApiContext);
};
