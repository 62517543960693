export const getParamUrl = (filters) => {
  let url = '';

  let params = '';

  if (filters?.length) {
    filters.forEach((filter, index) => {
      if (filter.key === 'link') {
        url = filter.value;
      } else if (index === 0) {
        params += `${filter.key}=${filter.value}`;
      } else {
        params += `&${filter.key}=${filter.value}`;
      }
    });
  }

  return {
    urlNew: url,
    params,
  };
};

export const formatUrl = (url) => {
  const newUrl = url.lastIndexOf('/') === url.length - 1 ? url : `${url}/`;
  return newUrl;
};
