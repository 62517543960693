import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Routes } from '../routes';
import { useApi } from './api';
import { formatUrl } from './util';

const baseUrl = process.env.REACT_APP_API_URL;
const baseUrlChat = process.env.REACT_APP_API_URL_CHAT;

// 1. Fetch một account cụ thể
export const useFetchAccount = (options) => {
  const api = useApi();

  const fetchAccount = async () => {
    const {
      data: { data },
    } = await api.get('/account');

    return data;
  };

  return useQuery('account', fetchAccount, {
    retry: (_, error) => {
      if (error?.response?.status === 401) {
        return false;
      }

      return true;
    },
    ...options,
  });
};

export const useFetchStore = (options) => {
  const api = useApi();

  const retry = (_, error) => {
    if (error?.response?.status === 401) {
      return false;
    }

    return true;
  };

  const queryOptions = {
    retry,
    ...options,
  };

  const fetchStore = async () => {
    const {
      data: { data },
    } = await api.get('/stores/store-details');
    return data;
  };

  return useQuery('store', fetchStore, queryOptions);
};

export const useRedirectIfLoggedIn = (options) => {
  const api = useApi();

  const fetchStore = async () => {
    const {
      data: { data },
    } = await api.get('/stores/store-details');

    return data;
  };

  const history = useHistory();

  return useQuery('store', fetchStore, {
    onSuccess: () => {
      history.push(Routes.Dashboard.path);
    },
    retry: (_, error) => {
      if (error?.response?.status === 401) {
        return false;
      }

      return true;
    },
    ...options,
  });
};

// 3. Cập nhập một account
export const useEditAccount = () => {
  return useMutation(['account'], (details) => {
    let result;
    if (details) {
      try {
        const token = `Bearer ${decodeURIComponent(localStorage.getItem('_pc-t'))}`;
        result = axios.put(`${baseUrl}account`, details, {
          headers: {
            Authorization: token,
          },
        });
      } catch (error) {
        throw new Error(error.message);
      }
    }

    return result;
  });
};

export const useSignin = () => {
  const signin = ({ email, password }) => {
    return axios.post(`${baseUrl}account/signIn`, {
      email,
      password,
    });
  };

  return useMutation(signin);
};

/* Token worksuite */
export const useChatToken = (options) => {
  const api = useApi();
  const tokenWS = () => {
    return api.post(`${baseUrlChat}worksuite/user-token`);
  };
  return useMutation(tokenWS, options);
};

export const useSignOut = () => {
  const api = useApi();

  const signOut = () => {
    return api.post(`${baseUrl}account/signOut`);
  };

  return useMutation(signOut);
};

export const useFetchStores = (filters) => {
  const token = `Bearer ${decodeURIComponent(localStorage.getItem('_pc-t'))}`;

  const apiUrl = new URL(baseUrl);
  const urlSearchParams = new URLSearchParams(apiUrl.search);

  const fetchStores = async () => {
    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        urlSearchParams.set(key, value);
      }
    }
    const url = urlSearchParams.toString()
      ? `${formatUrl(baseUrl)}stores?${urlSearchParams.toString()}`
      : `${formatUrl(baseUrl)}stores`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });

    return data;
  };

  return useQuery(['stores', filters], fetchStores);
};

// 2. Signup
export const signUp = async (account) => {
  const apiUrl = `${baseUrl}account`;
  return axios({
    method: 'POST',
    url: apiUrl,
    data: account,
  });
};

export const userInfo = () => {
  const user = JSON.parse(localStorage.getItem('user-info'));
  return user;
};

/*
 *   Create store
 */
export const useCreateStore = (options) => {
  const api = useApi();

  const createStore = (data) => {
    if (options.type === 'shopify_private') {
      return api.post('/stores/shopify', data);
    }

    return api.post('/stores', data);
  };
  return useMutation(createStore, options);
};

/*
 *   Update store
 */
export const useUpdateStore = (options) => {
  const api = useApi();

  const updateStore = (data) => api.put('/stores', data);

  return useMutation(updateStore, options);
};

/*
 *   Reset password
 */
export const usePasswordReset = () => {
  const api = useApi();

  const passwordReset = ({ password, token }) =>
    api.put(`/password-reset/${token}`, { new_password: password });

  return useMutation(passwordReset);
};

/*
 *   Change Password
 */
export const useUpdatePassword = (options) => {
  const api = useApi();

  const changePassword = (password) => {
    return api.put('/account/password-update/', password);
  };
  return useMutation(changePassword, options);
};

/*
 *   Get Token to reset
 */
export const useSendMailReset = () => {
  const api = useApi();

  const sendMailReset = ({ email }) => api.post('/password-reset/', { email });
  return useMutation(sendMailReset);
};

/*
 *   Email Verify
 */
export const useEmailVerify = (options) => {
  const api = useApi();

  const emailVerify = ({ token }) => api.put(`/email/verify/${token}`);
  return useMutation(emailVerify, options);
};

/*
 *   Email Verify
 */
export const useSendEmailVerify = (options) => {
  const api = useApi();

  const sendEmailVerify = ({ email, password }) =>
    api.post(`/email/verification-notification/`, {
      email,
      password,
    });
  return useMutation(sendEmailVerify, options);
};

/*
 *   Reset Password By Admin
 */
export const useResetPassWordByAdmin = (options) => {
  const api = useApi();

  const resetPassWord = ({ email }) => api.post(`/admin-password-reset/`, { email });
  return useMutation(resetPassWord, options);
};

export const useUpdateUserRole = (options) => {
  const api = useApi();

  const updateUserRole = ({ role, storeId }) => {
    if (!storeId) return;
    const data = api.put(`/update-store`, { role, store_id: storeId });
    return data;
  };
  return useMutation(updateUserRole, options);
};
