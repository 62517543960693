import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// core styles
import './scss/volt.scss';

// vendor styles
// TODO: Optimize this?
import '@fortawesome/fontawesome-free/css/all.css';

import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer, toast, Slide } from 'react-toastify';
import HomePage from './pages/HomePage';
import ScrollToTop from './components/ScrollToTop';
import { ApiProvider } from './api/api';
import './assets/css/custom.css';
import 'react-toastify/dist/ReactToastify.css';
import FuncChat from './components/Chat';

const queryClient = new QueryClient();

ReactDOM.render(
  <BrowserRouter>
    <ApiProvider>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <FuncChat />
        <HomePage />
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} transition={Slide} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ApiProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
