import React from 'react';
import { Spinner } from 'react-bootstrap';

// import ReactLogo from '../assets/images/mini-logo.png';

const Preloader = (props) => {
  const { isFullHeight, ...rest } = props;

  const { innerHeight } = window;
  const height = `${innerHeight}px` || '0px';

  return (
    <div
      className="preloader d-flex w-100 left-0 top-0 justify-content-center align-items-center"
      style={{
        height: `${isFullHeight ? height : '100%'}`,
      }}
    >
      <Spinner animation="border" variant="primary" {...rest} />
    </div>
  );
};

export default Preloader;
