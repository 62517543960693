import React from 'react';
import { Button as BSButton, Spinner } from 'react-bootstrap';
import cn from 'classnames';

const Button = React.forwardRef((props, ref) => {
  const { children, className, size, isLoading, ...rest } = props;

  const extClass = isLoading ? 'd-flex align-items-center' : '';

  const classes = cn(`fw-normal ${extClass}`, className);

  return (
    <BSButton ref={ref} className={classes} size={size} {...rest}>
      {isLoading && <Spinner className="me-2" size="sm" />}
      {children}
    </BSButton>
  );
});

export default Button;
