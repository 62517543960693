import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { Routes } from '../routes';
import MiniLogo from '../assets/images/mini-logo.png';
import Preloader from '../components/Preloader';
import Sidebar from '../components/Sidebar';

const Dashboard = React.lazy(() => import('./Dashboard'));
const Orders = React.lazy(() => import('./Orders'));
const Products = React.lazy(() => import('./Products'));
const PodProducts = React.lazy(() => import('./PodProducts'));
const EditOrder = React.lazy(() => import('./EditOrder'));
const AddDesign = React.lazy(() => import('./AddDesign'));
const EditDesign = React.lazy(() => import('./EditDesign'));
const Designs = React.lazy(() => import('./Designs'));
const EditProduct = React.lazy(() => import('./EditProduct'));
const PodProductPage = React.lazy(() => import('./PodProductPage'));
const AddProduct = React.lazy(() => import('./AddProduct'));
const Templates = React.lazy(() => import('./Templates'));
const AddTemplate = React.lazy(() => import('./AddTemplate'));
const CreateTemplate = React.lazy(() => import('./CreateTemplate'));
const EditTemplate = React.lazy(() => import('./EditTemplate'));
const ClipartsPage = React.lazy(() => import('./Cliparts'));
const FontsPage = React.lazy(() => import('./Fonts'));
const ImagesPage = React.lazy(() => import('./Images'));
const EditSide = React.lazy(() => import('./EditSide'));
const AddSide = React.lazy(() => import('./AddSide'));
const Side2 = React.lazy(() => import('./Side2'));
const SideTemplates = React.lazy(() => import('./SideTemplates'));
const Signin = React.lazy(() => import('./Signin'));
const Signup = React.lazy(() => import('./Signup'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));
const EmailVerify = React.lazy(() => import('./EmailVerify'));
const Settings = React.lazy(() => import('./Settings'));
const SettingsContact = React.lazy(() => import('./SettingsContact'));
const SettingsSecurity = React.lazy(() => import('./SettingsSecurity'));
const SettingsAppearance = React.lazy(() => import('./SettingsAppearance'));
const NotFound = React.lazy(() => import('./NotFound'));
const ServerError = React.lazy(() => import('./ServerError'));
const Welcome = React.lazy(() => import('./Welcome'));
const ImportProduct = React.lazy(() => import('./ImportProduct'));
const Inventory = React.lazy(() => import('./Inventory'));
const StoreList = React.lazy(() => import('./StoreList'));
const Payment = React.lazy(() => import('./Payment'));
const Subscription = React.lazy(() => import('./Subscription'));
const SocialLoginCallback = React.lazy(() => import('./SocialLoginCallback'));
const AuthCallBack = React.lazy(() => import('./AuthCallBack'));
const Authorize = React.lazy(() => import('./Authorize'));
const StepIntegration = React.lazy(() => import('./StepIntegration'));
const ProductPrintingOption = React.lazy(() => import('./ProductPrintingOption'));

const ProgressBar = React.lazy(() => import('../components/ProgessBar'));
const StepWizard = React.lazy(() => import('../components/StepWizard'));
const HelpDesk = React.lazy(() => import('./HelpDesk'));
const HelpDeskService = React.lazy(() => import('./HelpDeskService'));

const Project = React.lazy(() => import('./Project'));
const ProjectMilestone = React.lazy(() => import('./ProjectMilestone'));
const ProjectTicket = React.lazy(() => import('./ProjectTicket'));
const Integration = React.lazy(() => import('./Integration'));
const IntegrationWix = React.lazy(() => import('./IntegrationWix'));
const IntegrationShopify = React.lazy(() => import('./IntegrationShopify'));
const IntegrationWordpress = React.lazy(() => import('./IntegrationWordpress'));
const IntegrationApi = React.lazy(() => import('./IntegrationApi'));
const AdminSettings = React.lazy(() => import('./AdminSettings'));
const AdminSideTemplates = React.lazy(() => import('./AdminSideTemplates'));

const WizardCreateProduct = React.lazy(() => {
  return import('../components/StepWizard/Step1');
});
const WizardGetEmbed = React.lazy(() => import('../components/StepWizard/GetEmbed'));
const WizardCreateSide = React.lazy(() => {
  return import('../components/StepWizard/Step2');
});
const WizardAllSet = React.lazy(() => import('../components/StepWizard/AllSet'));
const AuthWrap = React.lazy(() => import('../components/AuthWrap'));

const RouteWithLoader = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Sidebar />
          <main className="content">
            <Component {...props} />
          </main>
        </>
      )}
    />
  );
};

const WizardRoutes = ({ component: Component, ...rest }) => {
  const [step, setStep] = React.useState(1);

  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="d-flex">
          <div
            style={{ width: '100px', height: '100vh', minWidth: '100px' }}
            className="bg-dark d-flex flex-column align-items-center"
          >
            <Image src={MiniLogo} className="mt-4" />
            <div className="d-flex flex-column justify-content-center flex-grow-1">
              <ProgressBar step={step} />
            </div>
          </div>
          <div
            style={{ overflowY: 'auto', height: '100vh' }}
            className="container wizard-container"
          >
            <Component {...props} setStep={setStep} />
          </div>
        </div>
      )}
    />
  );
};

const Root = () => (
  <React.Suspense fallback={<Preloader isFullHeight />}>
    <Switch>
      {/* pages */}
      <RouteWithSidebar exact path="/" component={Dashboard} />
      <RouteWithSidebar exact path={Routes.Orders.path} component={Orders} />
      <RouteWithSidebar exact path={Routes.Dashboard.path} component={Dashboard} />
      {/* <RouteWithSidebar exact path={Routes.AddProject.path} component={AddProject} /> */}
      <RouteWithSidebar exact path={Routes.EditOrder.path} component={EditOrder} />

      <RouteWithSidebar exact path={Routes.AddDesign.path} component={AddDesign} />
      <RouteWithSidebar exact path={Routes.EditDesign.path} component={EditDesign} />
      <RouteWithSidebar exact path={Routes.Designs.path} component={Designs} />
      <RouteWithSidebar exact path={Routes.Products.path} component={Products} />
      <RouteWithSidebar
        exact
        path={Routes.PodProducts.path}
        component={PodProducts}
      />
      <RouteWithSidebar
        exact
        path={Routes.ProductPrintingOption.path}
        component={ProductPrintingOption}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditProduct.path}
        component={EditProduct}
      />
      <RouteWithSidebar
        exact
        path={Routes.PodProductPage.path}
        component={PodProductPage}
      />
      <RouteWithSidebar exact path={Routes.AddProduct.path} component={AddProduct} />
      <RouteWithSidebar exact path={Routes.Templates.path} component={Templates} />
      <RouteWithSidebar
        exact
        path={Routes.SideTemplates.path}
        component={SideTemplates}
      />
      <RouteWithSidebar
        exact
        path={Routes.AddTemplate.path}
        component={AddTemplate}
      />
      <RouteWithSidebar exact path={Routes.Cliparts.path} component={ClipartsPage} />
      <RouteWithSidebar exact path={Routes.Fonts.path} component={FontsPage} />
      <RouteWithSidebar exact path={Routes.Images.path} component={ImagesPage} />
      <RouteWithSidebar exact path={Routes.HelpDesk.path} component={HelpDesk} />
      <RouteWithSidebar exact path={Routes.Project.path} component={Project} />
      <RouteWithSidebar
        exact
        path={Routes.Integration.path}
        component={Integration}
      />
      <RouteWithSidebar
        exact
        path={Routes.HelpDeskService.path}
        component={HelpDeskService}
      />
      <RouteWithSidebar
        exact
        path={Routes.ProjectTicket.path}
        component={ProjectTicket}
      />
      <RouteWithSidebar
        exact
        path={Routes.ProjectMilestone.path}
        component={ProjectMilestone}
      />
      <RouteWithSidebar
        exact
        path={Routes.IntegrationShopify.path}
        component={IntegrationShopify}
      />
      <RouteWithSidebar
        exact
        path={Routes.IntegrationWix.path}
        component={IntegrationWix}
      />
      <RouteWithSidebar
        exact
        path={Routes.IntegrationWordpress.path}
        component={IntegrationWordpress}
      />
      <RouteWithSidebar
        exact
        path={Routes.IntegrationApi.path}
        component={IntegrationApi}
      />
      <RouteWithSidebar
        exact
        path={Routes.AdminSettings.path}
        component={AdminSettings}
      />
      <RouteWithSidebar
        exact
        path={Routes.AdminSideTemplates.path}
        component={AdminSideTemplates}
      />
      <RouteWithLoader
        exact
        path={Routes.CreateTemplate.path}
        component={CreateTemplate}
      />
      <RouteWithLoader
        exact
        path={Routes.EditTemplate.path}
        component={EditTemplate}
      />
      <RouteWithSidebar exact path={Routes.EditSide.path} component={EditSide} />
      <RouteWithSidebar exact path={Routes.AddSide.path} component={AddSide} />
      <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
      <RouteWithSidebar
        exact
        path={Routes.SettingsContact.path}
        component={SettingsContact}
      />
      <RouteWithSidebar
        exact
        path={Routes.SettingsSecurity.path}
        component={SettingsSecurity}
      />
      <RouteWithSidebar
        exact
        path={Routes.SettingsAppearance.path}
        component={SettingsAppearance}
      />
      <RouteWithSidebar exact path={Routes.Payment.path} component={Payment} />
      <RouteWithSidebar
        exact
        path={Routes.Subscription.path}
        component={Subscription}
      />
      <RouteWithSidebar
        exact
        path={Routes.ImportProduct.path}
        component={ImportProduct}
      />
      <RouteWithSidebar exact path={Routes.Inventory.path} component={Inventory} />
      <RouteWithLoader
        exact
        path={Routes.IntegrationAuth.path}
        component={AuthWrap}
      />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader exact path={Routes.Welcome.path} component={Welcome} />
      <RouteWithLoader exact path={Routes.StoreList.path} component={StoreList} />
      <RouteWithLoader
        exact
        path={Routes.EmailVerify.path}
        component={EmailVerify}
      />
      <RouteWithLoader
        exact
        path={Routes.AuthCallBack.path}
        component={AuthCallBack}
      />
      <RouteWithLoader
        exact
        path={Routes.SocialLoginCallback.path}
        component={SocialLoginCallback}
      />
      <RouteWithLoader exact path={Routes.StepWizard.path} component={StepWizard} />
      <RouteWithLoader
        exact
        path={Routes.StepWizardAccessible.path}
        component={StepWizard}
      />
      <WizardRoutes
        path={Routes.WizardCreateProduct.path}
        component={WizardCreateProduct}
      />
      <WizardRoutes path={Routes.WizardGetEmbed.path} component={WizardGetEmbed} />
      <WizardRoutes
        path={Routes.WizardCreateSide.path}
        component={WizardCreateSide}
      />
      <WizardRoutes path={Routes.WizardAllSet.path} component={WizardAllSet} />
      <RouteWithLoader
        path={Routes.StepIntegration.path}
        component={StepIntegration}
      />
      <RouteWithLoader
        exact
        path={Routes.ResetPassword.path}
        component={ResetPassword}
      />
      <RouteWithLoader
        exact
        path={Routes.ForgotPassword.path}
        component={ForgotPassword}
      />
      <RouteWithLoader exact path={Routes.Authorize.path} component={Authorize} />
      <RouteWithLoader exact path={Routes.Side2.path} component={Side2} />
      <RouteWithLoader
        exact
        path={Routes.ServerError.path}
        component={ServerError}
      />
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFound} />
    </Switch>
  </React.Suspense>
);

export default Root;
