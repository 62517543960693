import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useChatToken, useFetchAccount } from '../api/account';
import Button from './Button';

const FuncChat = () => {
  const hideChat = !!process.env.REACT_APP_MODE_DEV;

  if (hideChat) {
    return <></>;
  }

  const token = localStorage.getItem('_pc-ws');

  const [tokenWS, setTokenWS] = React.useState(token);
  const [isVisible, setIsVisible] = React.useState(true);

  const { data: user } = useFetchAccount();

  const { mutate, isLoading: isLoadingToken } = useChatToken();

  React.useEffect(() => {
    if (user) {
      mutate(undefined, {
        onSuccess: (res) => {
          localStorage.setItem('_pc-ws', res.data?.data?.token);
          setTokenWS(res.data?.data?.token);
        },
      });
    }
  }, [user]);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://images-products.s3.us-east-1.amazonaws.com/popup-chat/chatcov2.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {isLoadingToken && (
        <div
          className="position-fixed rounded-circle bg-light"
          style={{
            bottom: '20px',
            right: '37px',
            zIndex: 9999,
          }}
        >
          <div
            className="bg-primary d-flex align-items-center rounded-circle justify-content-center"
            style={{
              width: '50px',
              height: '50px',
              opacity: '0.7',
            }}
          >
            <Spinner animation="border" variant="light" size="sm" />
          </div>
        </div>
      )}

      {!isLoadingToken && (
        <>
          <div
            style={{
              opacity: isVisible ? 1 : 0,
              visibility: isVisible ? 'visible' : 'hidden',
              display: isVisible ? 'block' : 'none',
            }}
          >
            <iframe
              id="chatco_popup"
              src={`https://api.cloodo.com/v1/popup_chat/?integrity=KkMRAH6ecCth2HAUizhY&tokenws=${tokenWS}`}
              width="0"
              height="0"
              title="Chat Popup"
              aria-hidden="true"
              style={{ display: 'none' }}
              z-index="3"
            />
          </div>
          <Button
            variant="light"
            onClick={handleButtonClick}
            className="position-fixed py-1 px-2 text-primary bg-gray-200"
            style={{ zIndex: 9999999, right: '4px', bottom: '80px' }}
          >
            <FontAwesomeIcon icon={isVisible ? faChevronRight : faChevronLeft} />
          </Button>
        </>
      )}
    </>
  );
};

export default FuncChat;
